import React, { Component } from "react"
import { Row, Col } from "react-flexbox-grid"
import { navigate } from "gatsby"

import Hero from "../../../components/Hero"
import Layout from "../../../components/Layout"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"

import circleImg from "../../../assets/images/sm_circle_ionvestors_2.png"
import headerHero from "../../../assets/images/header_contact.png"

const pageTitle = "Contact Us | Harmony Biosciences"
const pageDescription =
  "For product information, or to report an adverse event, contact Harmony Biosciences Medical Information department here. Find other company contact information."
const pageKeywords = ""

class contacts extends Component {
  constructor() {
    super()
  }

  privacyClick = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga("send", "event", "Button", "click", "Privacy Policy")
    }
    navigate("/privacy-policy-terms-of-use")
  }

  termsClick = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga("send", "event", "Button", "click", "Terms of Use")
    }
    navigate("/privacy-policy-terms-of-use#terms")
  }

  render() {
    return (
      <Layout jobCode="US-HAR-2200050/Jun 2022">
        <div id="contact-us">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug="/contact-us"
          />
          <Hero bg={headerHero}>
            <h1>Contact Us</h1>
          </Hero>
          <ContentBlock color="blue">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={circleImg} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <p>
                  <strong>
                    For product information, or to report an adverse event:
                  </strong>
                  <br />
                  Harmony Biosciences Medical Information Department <br />
                  <a href="mailto:medinfo@harmonybiosciences.com">
                    medinfo@harmonybiosciences.com
                  </a>
                  <br />
                  1-800-833-7460
                </p>

                <p>
                  <strong>For product patient support programs:</strong>
                  <br />
                  1-855-925-4948
                </p>

                <p>
                  <strong>For news media inquiries:</strong>
                  <br />
                  <a href="mailto:communications@harmonybiosciences.com">
                    communications@harmonybiosciences.com
                  </a>
                  <br />
                </p>

                <p>
                  <strong>For investor relations:</strong>
                  <br />
                  <a href="mailto:ir@harmonybiosciences.com">
                    ir@harmonybiosciences.com
                  </a>
                  <br />
                </p>

                <p>
                  <strong>Corporate Headquarters</strong>
                  <br />
                  <span className="txt-green">
                    <strong>Harmony Biosciences</strong>
                    <br />
                  </span>
                  630 W. Germantown Pike, Suite 215
                  <br />
                  Plymouth Meeting, PA 19462
                  <br />
                  484-539-9800
                  <br />
                </p>
                <p>&nbsp;</p>
                <p>
                  By using this site, you signify your assent to this{" "}
                  <a href="javascript:void(0);" onClick={this.privacyClick}>
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a to="javascript:void(0);" onClick={this.termsClick}>
                    Terms of Use
                  </a>
                  .
                </p>
              </Col>
            </Row>
          </ContentBlock>
        </div>
      </Layout>
    )
  }
}

export default contacts
